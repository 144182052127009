import { createGlobalStyle, css } from 'styled-components';
import commonStyles from './Common';
import Variables from './Variables';

const GlobalReset = css`
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/dm-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/dm-sans-v11-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/dm-sans-v11-latin-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('/fonts/dm-sans-v11-latin-regular.woff')
        format('woff'),
      /* Modern Browsers */ url('/fonts/dm-sans-v11-latin-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/fonts/dm-sans-v11-latin-regular.svg#DMSans') format('svg'); /* Legacy iOS */
  }
  /* Reset... */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    word-wrap: break-word;
    :focus-visible {
      outline: none;
    }
  }

  html,
  body {
    height: 100%;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  svg {
    vertical-align: middle;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  a {
    font-weight: 400;
    color: var(--black);
    font-size: 12px;
  }

  #root,
  #__next {
    isolation: isolate;
  }
  ul {
    list-style: none;
    padding: 0;
  }
`;

const StyledNProgress = css`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--primary);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #248fff, 0 0 5px #248fff;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: none;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #248fff;
    border-left-color: #248fff;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  *::-webkit-scrollbar-track {
    background: linear-gradient(
      90deg,
      #c5c5c5,
      #c5c5c5 1px,
      #c5c5c5 0,
      #c5c5c5
    );
  }
  *::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 8px;
    box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
      inset -2px -2px 2px rgb(0 0 0 / 25%);
  }

  *::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
`;
const BaseStyles = createGlobalStyle`
  ${Variables};
  ${GlobalReset};
  ${StyledNProgress};
  ${commonStyles}
`;

const GlobalStyles = () => <BaseStyles />;
export default GlobalStyles;
