import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import type { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';
import UserProvider from '@contexts/User';
import { useClient } from '@hooks/useClient';
import Loader from '@components/shared/Loader';
import Router from 'next/router';
import NProgress from 'nprogress';
import GlobalStyles from '../styles/Global';
import Bugsnag from 'plugins/bugsnag';
import React from 'react';
import { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

// -> Used for the loader when switching between pages
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
export const ErrorBoundary = plugin.createErrorBoundary(React);
export const ErrorView = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <p>something went wrong</p>
      <p>
        Click{' '}
        <a href="#" onClick={() => window.location.reload()}>
          here
        </a>{' '}
        to reload the page or contact support if the problem persists.
      </p>
    </div>
  </div>
);
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <>
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
          position="bottom-center"
          reverseOrder={false}
        />
        {page}
      </>
    ));
  const isClient = useClient();
  if (!isClient) return <Loader />;
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <UserProvider>
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
          position="bottom-center"
          reverseOrder={false}
        />
        <GlobalStyles />
        {getLayout(<Component {...pageProps} />)}
      </UserProvider>
    </ErrorBoundary>
  );
}
export default MyApp;
