import { css } from 'styled-components';

const Variables = css`
  :root {
    /* --space-small-x2: 4px;
    --space-small: 8px;
    --space-small-2x: 16px;
    --space-medium: 24px;
    --space-large: 32px;
    --dashboard-header-height: 60px;
    --side-menu-width-collapsed: 60px;
    --side-menu-width: 280px; */
  }
  :root {
    --white: #ffffff;
    --black: #000000;
    --radical-red: #ff3d57;
    --catalina-blue: #032170;
    --mine-shaft: #292929;
    --silver: #c9c9c9;
    --silver-light: #c5c5c5;
    --silver-light-2: #cbcbcb;
    --mercury: #e6e5e5;
    --boulder: #7a7a7a;
    --alto: #d9d9d9;
    --wild-sand: #f6f6f6;
  }
  :root {
    --primary: var(--catalina-blue);
    --text-color-1: var(--mine-shaft);
    --color-1: var(--silver);
    --color-2: var(--mercury);
    --color-3: var(--alto);
    --color-4: var(--boulder);
    --color-5: var(--silver-light);
    --color-6: var(--silver-light-2);
    --color-7: var(--wild-sand);
  }
`;

export default Variables;
