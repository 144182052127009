export function formatDate(arg: string) {
  try {
    const date = new Date(arg);
    const diff = new Date().getTime() - date.getTime(); // the difference in milliseconds

    if (diff < 1000) {
      // less than 1 second
      return 'right now';
    }

    const sec = Math.floor(diff / 1000); // convert diff to seconds

    if (sec < 60) {
      return `${sec} second${sec > 1 ? 's' : ''} ago`;
    }

    const min = Math.floor(diff / 60000); // convert diff to minutes
    if (min < 60) {
      return `${min} minute${min > 1 ? 's' : ''} ago`;
    }
    const hour = Math.floor(diff / (60000 * 60)); // convert diff to hours
    if (hour < 24) {
      return `${hour} hour${hour > 1 ? 's' : ''} ago`;
    }
    const day = Math.floor(diff / (60000 * 60 * 24)); // convert diff to days
    if (day < 30) {
      return `${day} day${day > 1 ? 's' : ''} ago`;
    }
    const month = Math.floor(diff / (60000 * 60 * 24 * 30)); // convert diff to months
    if (month < 12) {
      return `${month} month${month > 1 ? 's' : ''} ago`;
    }
    const year = Math.floor(diff / (60000 * 60 * 24 * 30 * 12)); // convert diff to year
    if (year < 12) {
      return `${year} year${year > 1 ? 's' : ''} ago`;
    }

    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    let d = [];
    d = [
      `0${date.getDate()}`,
      `0${date.getMonth() + 1}`,
      `${date.getFullYear()}`,
      `0${date.getHours()}`,
      `0${date.getMinutes()}`,
    ].map((component) => component.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return `${d.slice(0, 3).join('.')} ${d.slice(3).join(':')}`;
  } catch (error: unknown) {
    return '';
  }
}
