import styled from 'styled-components';

const StyledLoader = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  z-index: 100;
  svg {
    width: 3.75em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    circle {
      fill: none;
      stroke: var(--primary);
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      strokelinecap: round;
      animation: dash 1.5s ease-in-out infinite;
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dashoffset: -125px;
        }
      }
    }
  }
`;

export default function Loader({
  size = 'l',
}: // text = 'Loading...',
{
  size?: 'm' | 'l';
  text?: string;
}) {
  if (size === 'l') {
    return (
      <StyledLoader>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" strokeWidth="2" />
        </svg>
        {/* <span>{text}</span> */}
      </StyledLoader>
    );
  }

  return (
    <StyledLoader>
      <svg viewBox="12 12 24 24">
        <circle cx="24" cy="24" r="5" strokeWidth="1" />
      </svg>
      {/* <span>{text}</span> */}
    </StyledLoader>
  );
}
