import uploadToCloudinary from './uploadToCloudinary';
import { formatDate } from './formatDate';
import {
  formatCandidateProfile,
  formatCompanyProfile,
} from './formatProfileData';
import { ResumeDataType } from '@components/Profile/ResumeBuilder/data';
import { AxiosError } from 'axios';
import { api } from 'pages/api/services/baseurl';
import locationData from 'data/location.json';

export function getMsg(name: string) {
  return `${name} is required`;
}

export function slugify(str: string) {
  // change all characters except numbers and letters
  let slug = str.replace(/[^a-zA-Z0-9]/g, ' ');
  // remove multiple space to single
  slug = slug.replace(/  +/g, ' ');
  // remove all white spaces single or multiple spaces
  slug = slug.replace(/\s/g, '-').toLowerCase();
  return slug;
}

export const getUserToken = () => localStorage.getItem('userToken');

export const trimString = (text: string, limit: number) =>
  text.length > limit
    ? `${text.substring(0, limit - 3)}...`
    : text.substring(0, limit);

export const setToObjStorage = (key: string, value: object) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromObjStorage = <Data = unknown>(key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data) as Data;
};
export function isMoreThan24Hrs(datetime: Date) {
  const now = new Date();
  const timeDifference = now.getTime() - datetime.getTime();
  const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
  return timeDifferenceInHours > 24;
}

export function capitalizeString(str?: string) {
  if (!str) return '';
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
export const generatePdf = async (data: ResumeDataType) => {
  try {
    const { about, ...restPayload } = data;

    const payload = { ...restPayload, about: { ...about } };
    const response = await api.post('/resumes/generate-pdf', payload, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 400) {
      throw new Error('This feature is premium, please subscribe');
    }
    throw err; // Rethrow other errors
  }
};

interface ISortDate {
  createdAt: Date;
}
export const sortByCreationDate = (a: ISortDate, b: ISortDate) => {
  const createdAtA = a.createdAt;
  const createdAtB = b.createdAt;

  if (createdAtA && createdAtB) {
    return new Date(createdAtB).getTime() - new Date(createdAtA).getTime();
  } else if (createdAtA) {
    return -1; // Put a first if only b's createdAt is missing
  } else if (createdAtB) {
    return 1; // Put b first if only a's createdAt is missing
  }

  return 0; // No createdAt values, leave order unchanged
};
function replaceNullsWithEmptyStrings(obj: { [k: string]: any }): {
  [k: string]: unknown;
} {
  return Object.entries(obj).reduce(
    (newObj: { [k: string]: any }, [key, value]: [string, any]) => {
      newObj[key] = value === null ? '' : value;
      return newObj;
    },
    {}
  );
}

export {
  formatCandidateProfile,
  formatCompanyProfile,
  formatDate,
  uploadToCloudinary,
  replaceNullsWithEmptyStrings,
};
