import { StaffRole } from 'utils/customTypes';
import {
  StudentProfile,
  studentInitData,
  fileInitObj,
  ICandidateForm,
  companyInitData,
  IEmployerForm,
} from 'utils/model/auth';

export const formatCandidateProfile = (userData?: StudentProfile) => {
  if (!userData)
    return {
      ...studentInitData,
      resume: fileInitObj,
      profile_picture: fileInitObj,
    };
  const { profile_picture, resume, ...rest } = userData;
  const newObj: { [k: string]: string } = { ...rest };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item]) delete newObj[item];
  });
  return Object.assign(studentInitData, {
    ...newObj,
    profile_picture: {
      link: profile_picture || '',
    },
    resume: {
      name: resume ? 'my_resume' : '',
      link: resume || '',
    },
  }) as Omit<ICandidateForm, 'step1' | 'step2'>;
};
export const formatCompanyProfile = (userData?: any, role?: StaffRole) => {
  if (!userData)
    return {
      ...companyInitData,
      logo: fileInitObj,
      profilePics: fileInitObj,
    };
  const { logo, profilePics, ...rest } = userData;
  const newObj: { [k: string]: string | object } = { ...rest };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item]) delete newObj[item];
  });
  return Object.assign(companyInitData, {
    ...newObj,
    role,
    profilePics: {
      link: profilePics || '',
    },
    logo: {
      link: logo || '',
    },
  }) as Omit<IEmployerForm, 'step1'>;
};
