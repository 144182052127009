export enum AccountType {
  Default = '',
  Student = 'student',
  Employer = 'company',
}

export enum AccountStatus {
  Default = '',
  Verified = 'verified',
  NotVerified = 'not-verified',
}

export enum StaffRole {
  Default = '',
  BranchLead = 'branch_lead',
  TeamMember = 'team_member',
  SuperAdmin = 'super_admin',
}

export const dashboardData = {
  recent_application: 0,
  jobs_posted: 0,
  recent_shortlisted: 0,
  recent_activity: [],
  jobs_saved: 0,
  profile_views: 0,
};
const companyRegistrationData = {
  step: 1,
  name: 'Abiodun Enterprice',
  state: 'Abuja',
  city: 'FCT',
  address: 'No 2 Abuja',
  email: 'abiodunolanlokunbakinde@gmail.com',
  phone_number: '08147645676',
  industry: 'Hospitality',
  logo: '',
  description: '',
  size: '',
  username: '',
  profile: '',
};
const branchData = {
  company_id: '167714519658896759',
  name: 'Abiodun Enterprice',
  state: 'Ibadan',
  city: 'FCT',
  email: 'brandcdh@enterprice.com',
  address: 'No 2 Sola street ',
  phone_number: '0812763356763',
};
const teanData = {
  branch_id: '',
  company_id: '',
};
const paystack = {
  reference: '1686326934435',
  trans: '2870962014',
  status: 'success',
  message: 'Approved',
  transaction: '2870962014',
  trxref: '1686326934435',
  redirecturl:
    'https://hireme-v1.vercel.app/transcript/confirm-payment?trxref=1686326934435&reference=1686326934435',
};
export type CompanyData = typeof companyRegistrationData;
export type BranchData = typeof branchData & {
  action_type: string | null;
};
export type TeamData = typeof teanData;

export type DashboardType = typeof dashboardData;
export type PaystackResponse = typeof paystack;
