import { css } from 'styled-components';

const CommonStyles = css`
  .error-msg {
    position: absolute;
    top: 100%;
    left: 0;
    color: var(--color-error, red);
    font-size: 12px;
    font-weight: 400;
    font-size: 14px;
  }
  form {
    display: grid;
    row-gap: 24px;
  }
  label {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
  }
  .form-action {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .text-disabled {
    color: var(--color-5);
    pointer-events: none;
  }
  .col-half-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      width: calc(50% - 35px);
    }
  }
  .loading {
    position: relative;
    :after {
      position: absolute;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: '\\2026';
      width: 0px;
    }
  }
  @keyframes ellipsis {
    to {
      width: 10px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 10px;
    }
  }
  .required {
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
  }
  .bg-light {
    background: var(--white);
  }
  .actions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    padding: 0 16px;
    > div {
      background: #ffffff;
      box-shadow: 7.84416px 2.94156px 21.5714px 9.80519px rgba(0, 0, 0, 0.13);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 16px;
      max-width: 543px;
      margin: 0 auto;
    }
  }
  .bookmark-wrapper {
    background: #ececec;
    border-radius: 8px;
    padding: 12px 11px;
    padding-top: 10px;
    &.active {
      background: var(--primary);
      > svg {
        stroke: var(--white);
      }
    }
  }
  .text--underlined {
    text-decoration: underline !important;
  }
  .field-join-no-gap {
    display: flex;
    > * {
      flex: 1;
      :first-child {
        > .form-control,
        .hireme__control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-width: 0.5px;
          border-left-width: 0.5px;
        }
      }
      :last-child {
        > .form-control,
        .hireme__control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .field-with-gap {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    > * {
      width: calc((100% - 28px) / 2);
    }

    @media (max-width: 450px) {
      flex: 1;
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
  .step-1 {
    max-width: calc(100% - 332px) !important;
    margin: 0 auto;
    @media (max-width: 887px) {
      max-width: 555px !important;
    }
  }
  .full-width {
    width: 100% !important;
  }
`;

export default CommonStyles;
