import { JobItemProps } from '@components/job/props';
import { AccountType, AccountStatus, StaffRole } from 'utils/customTypes';

const parent_company = {
  public_id: '',
  city: '',
  address: '',
  email: '',
  phone_number: '',
  industry: '',
  username: '',
  size: '',
  company_type: '',
  name: '',
  state: '',
  description: '',
  profile: '',
  logo: '',
  background_image: '',
};
export interface IFile {
  name?: string;
  link: string;
}
export interface UserData {
  uid?: string;
  first_name: string;
  last_name: string;
  email: string;
  background_image?: string;
  account_type: AccountType;
  current_step: number;
  student_profile?: StudentProfile;
  company?: CompanyProfile;
  account_status: AccountStatus;
  display_name?: string;
  recent_jobs?: JobItemProps[];
  phone_number?: string;
  address?: string;
  gender?: string;
  role?: StaffRole;
  username: string;
  title: string;
  about: string;
  logo: string;
  is_approved?: boolean;
  referred_by?: string;
  public_id?: string;
  profile_picture?: string;
  employee_profile?: {
    title: string;
    about: string;
  };
  subscription?: { [k: string]: boolean };
}

export const initData = {
  first_name: '',
  last_name: '',
  email: '',
  account_type: AccountType.Default,
  current_step: 0,
  account_status: AccountStatus.Default,
};

export type FileObjType = {
  name?: string;
  link: string;
};
export const fileInitObj: FileObjType = {
  name: '',
  link: '',
};

export const companyInitData = {
  role: StaffRole.Default,
  address: '',
  phone_number: '',
  size: '',
  name: '',
  company_id: '',
  branch_id: '',
  branch_name: '',
  state: '',
  title: '',
  personalUsername: '',
  profilePics: '',
  about: '',
  city: '',
  username: '',
  email: '',
  industry: '',
  services: '',
  logo: '',
  description: '',
  profile: '',
  public_id: '',
  website_url: '',
  social_media: {
    twitter: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    tiktok: '',
  },
  parent_company,
};
export type CompanyProfile = typeof companyInitData & {
  role?: StaffRole;
  background_image?: string;
  subscription?: null | {
    category: string;
    name: string;
  };
  parent_company: typeof parent_company & {
    subscription?: { [k: string]: boolean };
  };
};
type ICompany = Omit<CompanyProfile, 'logo' | 'profilePics'>;
export interface IEmployerForm extends ICompany {
  logo: FileObjType;
  profilePics: FileObjType;
  step1?: string;
}
interface Reg {
  account_type: string;
  step: string;
}
export interface StudentReg extends Reg {
  profile: StudentProfile;
}
export interface CompanyReg extends Reg {
  profile: CompanyProfile;
}
export const studentInitData = {
  username: '',
  academic_status: '',
  institution_type: '',
  school_name: '',
  course_of_study: '',
  graduating_year: '',
  student_id: '',
  cgpa: '',
  profile_picture: '',
  resume: '',
  description: '',
  about: '',
  current_year: '',
  promo_code: '',
  cv_link: '',
};
export type StudentProfile = typeof studentInitData;
type ICandidate = Omit<StudentProfile, 'resume' | 'profile_picture'>;
export interface ICandidateForm extends ICandidate {
  resume: IFile;
  profile_picture: IFile;
  step1: string;
  step2: string;
}
