import axios from 'axios';

export default async function uploadToCloudinary(fileToUpload: File) {
  const cloudName = process.env.NEXT_PUBLIC_CLOUD_NAME as string;
  const uploadPreset = process.env.NEXT_PUBLIC_UPLOAD_PRESET as string;
  const formData = new FormData();
  formData.append('file', fileToUpload);
  formData.append('upload_preset', uploadPreset);
  const response = await axios.post<{ secure_url: string }>(
    `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
    formData
  );
  return response.data.secure_url;
}
