import axios from 'axios';
import { getUserToken } from 'utils/helpers';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL + '/api';
const client = axios.create({
  baseURL,
});

// Create an instance of Axios
export const api = axios.create({
  baseURL, // replace with your API base URL
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Retrieve the bearer token from localStorage
    const token = getUserToken();
    config.headers = config.headers ?? {};
    if (token) {
      // Set the Authorization header with the bearer token
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // Abort the request if the token is not available
      return Promise.reject(new Error('Token not available'));
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default client;
