import { IMessageUser } from "@components/Chat/chatType";
import useRequest from "@hooks/useSwrFetch";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { AccountType } from "utils/customTypes";
import { UserData } from "utils/model/auth";

type UserState = {
  code?: string;
  step?: number;
  account_type?: AccountType;
  email?: string;
  userMessageData?: Pick<
    IMessageUser,
    "first_name" | "last_name" | "profile_picture" | "public_id"
  > | null;
};
type CurrentUser = {
  data?: UserData;
  userState?: UserState;
  error?: string;
};

const UserContextState = createContext<CurrentUser>({});
const UserContextDispatch = createContext<
  React.Dispatch<React.SetStateAction<UserState>>
>(() => {});

interface IUserProvider {
  children: ReactNode;
}

const UserMutateDispatch = createContext(() => {});
export default function UserProvider({ children }: IUserProvider): JSX.Element {
  const { data: user, error } = useRequest<
    {
      message: string;
      data: UserData;
    },
    { message: string }
  >("/profile", { revalidateOnFocus: false });

  const [userState, setUserState] = useState<UserState>({});
  const { code, step, account_type, userMessageData } = userState;
  const value = useMemo(
    () => ({
      data: user?.data,
      userState: { code, step, account_type, userMessageData },
      error: error?.message,
    }),
    [account_type, code, error, step, user?.data, userMessageData],
  );
  return (
    <UserContextState.Provider value={value}>
      <UserContextDispatch.Provider value={setUserState}>
        {children}
      </UserContextDispatch.Provider>
    </UserContextState.Provider>
  );
}

export function useUserContextState(): CurrentUser {
  const context = useContext(UserContextState);

  if (context === undefined) {
    throw new Error("useUserContextState must be used within a UserProvider");
  }

  return context;
}

export function useUserMutateDispatch(): React.Dispatch<
  React.SetStateAction<UserState>
> {
  const context = useContext(UserMutateDispatch);

  if (context === undefined) {
    throw new Error(
      "useUserContextDispatch must be used within a UserProvider",
    );
  }

  return context;
}
export function useUserContextDispatch(): React.Dispatch<
  React.SetStateAction<UserState>
> {
  const context = useContext(UserContextDispatch);

  if (context === undefined) {
    throw new Error(
      "useUserContextDispatch must be used within a UserProvider",
    );
  }

  return context;
}
